import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import styles from "./index.module.css";

const getButton = (variant = "outlined", fullWidth, text, size, noMargin, width, disabled, submit,fontSize,padding) => {
  return (
    <Button
      variant={variant}
      color="secondary"
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={submit}
      style={{ margin: !noMargin && 10, width: width,padding: padding && padding }}
    >
      <Typography
        variant="button"
        style={{
          minWidth: size && size,
          padding: size && "4vw auto",
          fontFamily: "'DM Sans', sans-serif",
          fontWeight: 700,
          fontSize: fontSize ? fontSize : 15,
          letterSpacing: "1.25px",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

const OutlinedButton = (props) => {
  return (
    <Link
      to={props.link}
      className={`${styles["enroll-button"]} ${styles["outlineButton"]}`}
    >
      {getButton(props.variant, props.fullWidth, props.text, props.size, props.noMargin, props.width)}
    </Link>
  );
};

const SolidButton = (props) => {
  return (
    <Link to={props.link} className={styles["enroll-button"]}>
      {getButton("contained", props.fullWidth, props.text, props.size, props.noMargin, props.width, props.disabled, props.submit)}
    </Link>
  );
};

const FilledButton = (props) => {
  return (
    <div className={styles["enroll-button"]} style = {{width:props.blockSize && props.blockSize}}>
      {getButton("contained", props.fullWidth, props.text, props.size, props.noMargin, props.width, props.disabled, props.submit,props.fontSize,props.padding)}
    </div>
  );
};

const BorderButton = (props) => {
  return (
    <div className={`${styles["enroll-button"]} ${styles["outlineButton"]}`} style = {{width:props.blockSize && props.blockSize}}>
      {getButton(props.variant, props.fullWidth, props.text, props.size, props.noMargin, props.width, props.disabled, props.submit,props.fontSize,props.padding)}
    </div>
  );
};

const SubmitButton = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onSubmit={props.action}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      type="submit"
      className={styles["enroll"]}
    >
      {props.text}
    </Button>
  );
};

export { OutlinedButton, SolidButton, SubmitButton, FilledButton, BorderButton };
