import React from 'react';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import '@fontsource/roboto';
import '@fontsource/atkinson-hyperlegible';
import '@fontsource/poppins';
import colors from 'assets/css/colors.module.css';

const theme = createTheme(adaptV4Theme({
  palette: {
    background: {
      default: 'white'
    },
    secondary: {
      main: colors.brand
    }
  },
  typography: {
      fontFamily: [ 'Poppins'],
      h1: {
        fontSize: '40px',
        lineHeight: '48px',
        marginBottom: '36px',
        fontWeight: 700
      },
      h2: {
        color: colors.brand,
        fontSize: '32px',
        fontWeight: 700
      },
      h3: {
        fontSize: '22px',
        fontWeight: 700
      },
      h4: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '27px'
      },
      p: {
        fontSize: '16px',
        lineHeight: '22px',
        fontFamily: 'Roboto'
      }
    }
}));

const Theme = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Theme;