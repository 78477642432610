import React from "react";
import { Fade, Box, Typography } from "@mui/material";
import PlayStoreImage from "../../assets/img/google-play-badge.png";
import AppleImage from "../../assets/img/Apple_Logo.svg";
import WarningImage from "../../assets/img/Vector.png";
import ErrorImage from '../../assets/img/Vector (4).png';
import CloseIcon from "@mui/icons-material/Close";
import classes from "./index.module.css";
import { USER_EXIST_CODE_CVS_1 } from "constants/app";

export const ErrorModal = (props) => {
  
  const getMessage = (noSubMessage,ifCodeExist) => {
    if(noSubMessage) return props.message;
   else if(props.subMessage) return props.subMessage
   else if (ifCodeExist) return ifCodeExist;
  };

  const isCodeTrue = () => {
    if (props.code === USER_EXIST_CODE_CVS_1) {
      return true;
    }
    return false;
  }

  const getStyle = () => {
    return {
 position: "absolute",
top: "50%",
left: "50%",
transform: "translate(-50%, -50%)",
width: "auto",
bgcolor: "background.paper",
border: "2px solid #000",
boxShadow: 24,
p: 2,
border: "none !important",
borderRadius: props.smallBox ? 1.5 : 2,
    }
}

  return (
    <Fade in={props.open}>
      <Box sx={getStyle()}>
        <div className={classes.crossStyle}>
          <CloseIcon
            style={{
              background: "#F3F5FA",
              padding: "0.25rem",
              borderRadius: 5,
              cursor: "pointer",
              color: "#174E8C",
              border: props.subMessage && "1px solid #174E8C"
            }}
            onClick={props.handleClose}
          />
        </div>
        <div className={classes.modalStyle}>
          <img
            src={isCodeTrue() ? ErrorImage : WarningImage}
            style={{ height: 44, width: 50, marginBottom: "1rem" }}
          />
          <Typography
            id="transition-modal-title"
            variant="h5"
            style={{
              fontFamily: "'DM Sans', sans-serif",
              fontWeight: 700,
              textAlign: "center",
              fontSize: props.subMessage ? 23 : 19,
               margin: !props.subMessage && "0.4rem auto",
    // lineHeight: props.subMessage && 28,
    textAlign: "center" 
            }}
          >
            {getMessage(true)}
          </Typography>
          <Typography
            id="transition-modal-title"
            variant="body1"
            style={{
              fontFamily: "'DM Sans', sans-serif",
              fontWeight:props.subMessage ? 500 : 400,
              textAlign: "center",
              fontSize: 16,
              color: "#142A39",
              // lineHeight:props.subMessage && 21,
              letterSpacing:props.subMessage && 0.02,
              margin:props.subMessage && "7px auto"
            }}
          >
            {getMessage(false,(isCodeTrue() ? "Please use a different email address to create your KardiaComplete account." ? props.smallBox : <>
                <span>Please contact </span>
                <a href="mailto:support@alivecor.com">support@alivecor.com.</a>
              </> : "Follow the link below for your respective device to download the KardiaComplete App.")
            )}
          </Typography>
          {(!isCodeTrue() && !props.subMessage) ? (
            <>
              <Typography
                id="transition-modal-title"
                variant="body1"
                style={{
                  fontFamily: "'DM Sans', sans-serif",
                  fontWeight: 400,
                  textAlign: "center",
                  fontSize: 15,
                  color: "#142A39"
                }}
              >
                Then log in to begin enjoying your KardiaComplete experience.
              </Typography>
              <div
                style={{
                  margin: "1.5rem auto",
                  textAlign: "center",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <img src={PlayStoreImage} /> <img src={AppleImage} />
              </div>
            </>
          ) : <div style={{ marginBottom: 20 }}></div>}
        </div>
      </Box>
    </Fade>
  );
};
