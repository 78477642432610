import Shell from "components/shell";
import React, { useState, createRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import copy from "constants/copy";
import styles from "./index.module.css";
import StepperMessage from "components/stepperMessage";
import { Input, isFormValid } from "components/controls";
import { FilledButton } from "components/buttons";
import { useDispatch, useSelector } from "react-redux";
import { loginAPI, userProfile, verifyEmail } from "data";
import ErrorImage from "../../assets/img/Vector (9).png";
import { userInfoAction } from "store/actions";
import QrModal from "components/Qr";
import { screenNames } from "constants/enrollment";
import { ErrorModal } from "components/errorModal";
import { dispatchUserProgress } from "store/actions/user";
import * as textConstant from "./constant";

export const Login = (props) => {
  const [subject, setSubject] = useState(useParams().subject);
  const [loading, updateLoading] = useState(false);
  const [loginValues, updateLoginValues] = useState({});
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [submitable, updateSubmitable] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const [kccCode, setKCCCode] = useState(null);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const formRef = createRef();
  const [open, setOpen] = useState({
    open: false,
    // target: null,
    // message: null,
    code: null,
  });
  const handleClose = () => {
    setOpen({ open: false });
  };
  const handleOpen = (data = [], message = "", code = "") =>
    setOpen({ open: true, message: message, code: code });

  const [openErrorModal, setOpenErrorModal] = useState({
    open: false,
    // target: null,
    message: null,
    code: null,
  });
  const handleErrorModalClose = () => {
    setOpenErrorModal({ open: false });
  };
  const handleErrorModalOpen = (data = [], message = "", code = "") =>
    setOpenErrorModal({ open: true, message: message, code: code });

  const messages = {
    title: copy(`enrollment.loginTitle.${subject}`, ["BOLD Capital Partners"]),
    subtitle: copy(`enrollment.loginSubTitle.${subject}`),
    emailLabel: copy(`enrollment.form.email.${subject}`),
    passwordLabel: copy(`enrollment.form.password.${subject}`),
  };

  const handleRegistrationChange = (e) => {
    const target = e.target;
    let newValue = target.value;
    const name = target.name;
    updateLoginValues({ ...loginValues, [name]: newValue });

    updateSubmitable(isFormValid(formRef.current));
  };
  useEffect(() => {
    updateSubmitable(isFormValid(formRef.current));
  }, []);

  const fieldConfigs = {
    email: {
      type: "email",
      label: messages.emailLabel,
      value: loginValues.email,
      name: "email",
      onChange: handleRegistrationChange,
      validation: ["email"],
    },
    password: {
      type: "password",
      label: messages.passwordLabel,
      value: loginValues.password,
      passwordOpen: passwordOpen,
      setPasswordOpen: setPasswordOpen,
      name: "password",
      onChange: handleRegistrationChange,
      validation: ["string"],
      noPassword: true,
    },
  };

  const callUserProfileAPI = async (url) => {
    try {
      const data = await userProfile(url);
      const res = await data.json();
      const object = {
        AccountCreation: screenNames.PROFILE,
        SetupProfile: screenNames.SHIPPING,
        ShippingAddress: screenNames.CONFIRMATION,
      };

      //store user progress step in redux which can be used other components to ensure correct Stepper is displayed.
      dispatch(dispatchUserProgress(res[0]?.stepID));

      if (res[0]?.stepID === "ShippingAddress") handleOpen();
      else props.handleNavigate(object[res[0]?.stepID]);
    } catch (err) {
      getErrorFunc();
      console.log(err);
    }
  };
  const getErrorFunc = () => {
    updateLoading(false);
    handleErrorModalOpen();
  };
  const onSubmit = async () => {
    try {
      updateLoading(true);
      const data = await loginAPI(loginValues);
      setStatusCode(data.status);
      const res = await data.json();
      if (data.status === 200) {
        dispatch(
          userInfoAction({
            auth_token: res.auth_token,
            public_id: res.public_id,
            first_name: res.first_name,
            last_name: res.last_name,
            patient_dob: res.patient_dob,
          })
        );

        let verifyEmailData = await verifyEmail({ email: loginValues?.email });
        let verifyEmailRes = await verifyEmailData.json();
        setKCCCode(verifyEmailRes?.isKCCUser === true ? 200 : 400);
        if (verifyEmailRes?.isKCCUser === true) {
          callUserProfileAPI(res.auth_token);
          updateLoading(false);
        } else {
          getErrorFunc();
          return;
        }
      } else {
        getErrorFunc();
        return;
      }
    } catch (err) {
      getErrorFunc();
      console.log(err);
    }
  };
  return (
    <Shell>
      <Grid container className={styles.container}>
        <Grid item xs={0} md={0} lg={2}></Grid>
        <Grid item xs={12} md={12} lg={8} className={styles.titles}>
          <StepperMessage
            title={messages.title}
            subtitle={messages.subtitle}
            activeStep={0}
            login={true}
          />
          <Typography
            variant="subtitle1"
            className={styles["enrollLink"]}
            style={{ marginTop: "-3.5vh" }}
          >
            {textConstant.LOGIN_HEADER}
            <span
              className={styles.createAccount}
              onClick={() =>
                typeof props.handleNavigate === "function" &&
                props.handleNavigate(screenNames.ACCOUNT)
              }
            >
              {textConstant.CREATE_ACCOUNT_LINK_TEXT}
            </span>
          </Typography>
        </Grid>
        <Grid xs={0} md={0} lg={2}></Grid>
      </Grid>
      <Grid
        sm={12}
        md={12}
        lg={6}
        container
        order={{ xs: 3, md: 2 }}
        className={styles.backdropContainer}
      >
        <Backdrop open={loading} className={styles.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <form
        // onSubmit={handleSubmit}
        className={styles["registration-form"]}
        ref={formRef}
      >
        <Grid container spacing={1} className={styles["form-fields"]}>
          <Grid item xs={12} className={styles["form-row"]}>
            <Input {...fieldConfigs.email} />
          </Grid>
          <Grid
            item
            xs={12}
            className={styles["form-row"]}
            style={{ marginTop: 30 }}
          >
            <Input {...fieldConfigs.password} />
          </Grid>
          <a
            href={`${process.env.REACT_APP_ALIVECOR_ENDPOINT}account/resend_password`}
            className={styles["passwordVal"]}
          >
            {textConstant.FORGOT_PASSWORD_LINK_TEXT}
          </a>
          {statusCode !== 200 && statusCode !== null && (
            <div className={styles.redBox}>
              <img src={ErrorImage} style={{ width: 30, height: 25 }} />
              <div style={{ marginLeft: 6 }}>
                {statusCode === 401 || statusCode === 400
                  ? textConstant.INVAILD_USERNAME_ERROR
                  : textConstant.DEFAULT_ERROR_TEXT}
              </div>
            </div>
          )}
        </Grid>
        <div className={styles.buttonWrapper}>
          {" "}
          <FilledButton
            sponsor={useParams().sponsor}
            text={`Log In`}
            width={"100%"}
            noMargin={true}
            disabled={!submitable}
            submit={onSubmit}
          />
        </div>
      </form>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <QrModal
            handleClose={handleClose}
            open={open.open}
            // target={open.target}
            // message={open.message}
            code={open.code}
          />
        </Modal>
      </div>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openErrorModal.open}
          onClose={handleErrorModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <ErrorModal
            handleClose={handleErrorModalClose}
            open={openErrorModal.open}
            code={openErrorModal.code}
            message={
              statusCode === 401 || statusCode === 400
                ? textConstant.USER_NOT_FOUND_ERROR
                : kccCode !== 200
                ? textConstant.ACCOUNT_EXIST
                : textConstant.DEFAULT_ERROR_TEXT
            }
            subMessage={
              kccCode !== 200 ? (
                textConstant.ERROR_SUBTEXT
              ) : (
                <>
                  <span>Please contact </span>
                  <a href="mailto:support@alivecor.com">
                    support@alivecor.com.
                  </a>
                </>
              )
            }
          />
        </Modal>
      </div>
    </Shell>
  );
};

export default Login;
