import React from 'react';
import { Fade, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningImage from '../../assets/img/vector (10).png';
import QrCode from '../../assets/img/QrCode.png'
import styles from './index.module.css';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    border: "none !important",
    borderRadius: 2,
};

export const QrModal = (props) => {
    return (
        <Fade in={props.open}>
            <Box className= {styles.boxStyle} sx={style}>
                <div className={styles.crossStyle}>
                    <CloseIcon
                        style={{
                            background: "#F3F5FA",
                            padding: "0.30rem",
                            borderRadius: 5,
                            cursor: "pointer",
                            color: "#174E8C",
                            border: "1px solid #174E8C"
                        }}
                        onClick={props.handleClose}
                    />
                </div>
                <div className={styles.bodyModal}>
                    <img src={WarningImage} style={{ height: 55, width: 55, marginBottom: 10 }} />
                    <div className={styles.MainText}>You’ve already completed KardiaComplete enrollment</div>
                    <div className={styles.subText}>Follow scan the QR code below to download the KardiaComplete app. Then log in to begin enjoying your KardiaComplete experience.</div>
                    <img src={QrCode} style = {{height:220,width:220}} />
                </div>
            </Box>
        </Fade>
    )
}

export default QrModal