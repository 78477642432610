import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import reduxThunk from 'redux-thunk';


const composeEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(reduxThunk)),
);

if (module.hot) {
  module.hot.accept();
}

export { store };