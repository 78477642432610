const app = {
  ENDPOINT: process.env.REACT_APP_KCC_KARDIA_ENDPOINT,
  steps: [
    {
      name:'Step 1',
      title: ''
    },
    {
      name:'Step 2',
      title: ''
    },
    {
      name:'Step 3',
      title: ''
    },
    {
      name:'Step 4',
      title: ''
    },
    {
      name:'Step 5',
      title: ''
    },
  ]
}
const registration = {
  "id":"someId",
  "user": {},
  "sponsor": {},
  "consents": {}
}
export { app, registration };