import React from 'react';
import { Fade, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningImage from "../../assets/img/Vector.png";
import styles from './index.module.css';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    border: "none !important",
    borderRadius: 2,
};

export const ErrorBox = (props) => {
    const getStyle = () => {
        return {
            position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    border: "none !important",
    borderRadius: 2,
        }
    }
console.log(props.text)
    return (
        <Fade className= {styles.boxStyle} in={props.open}>
            <Box sx={getStyle()}>
                <div className={styles.crossStyle}>
                    <CloseIcon
                        style={{
                            background: "#F3F5FA",
                            padding: "0.30rem",
                            borderRadius: 5,
                            cursor: "pointer",
                            color: "#174E8C",
                            border: "1px solid #174E8C"
                        }}
                        onClick={props.handleClose}
                    />
                </div>
                <div className={styles.bodyModal}>
                    <img src={WarningImage} style={{ height: 50, width: 55, marginBottom: 10 }} />
                    <div>{props.text}</div>
                    <div className={styles.subText}>{props.subText}</div>
                </div>
            </Box>
        </Fade>
    )
}

export default ErrorBox