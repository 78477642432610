import * as actionTypes from '../actions/actiontype';

const initialState = {
  auth_token: "",
  public_id: "",
  userProgress: ''
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_INFO:
      return {
        data: action.payload,
      };
    case actionTypes.USER_PROGRESS:
      return {
        ...state,
        userProgress: action.payload,
      };
    default:
      return state;
  }
};

export default userInfoReducer;
