import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography, Hidden } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Shell from "../shell";
import desk from "./../../assets/img/desk.png";
import illustration from "../../assets/img/illustration.svg";
import hero from "../../assets/img/hero.svg";
import devices from "./../../assets/img/devices.png";
import { OutlinedButton, SolidButton } from "../buttons";
import styles from "./index.module.css";
import globalStyles from "../../assets/css/index.module.css";

const Hero = (props) => {
  const clientHostMap = {
    "bold.kardia.com": {
      sponsor: "BC",
      value: "bold",
    },
  };
  const navigate = useNavigate();
  useEffect(() => {
    let { host } = window.location;
    if (clientHostMap[host]) {
      let pattern = new RegExp(`${clientHostMap[host].value}`);
      if (pattern.test(host)) {
        navigate(`/enroll/${clientHostMap[host].value}`);
      }
    }
  }, []);

  useEffect(() => {
    const values = JSON.parse(localStorage.getItem("_data"));
    if (values) {
      values.success && localStorage.clear();
    }
  }, []);
  const gridStyle = makeStyles(() => ({
    keepRight: {
      display: "flex",
      flexDirection: "column",
    },
    bottomSpacing: {
      marginBottom: "100px",
    },
  }));
  const gridStyles = gridStyle();
  const params = useParams();
  return (
    <Shell>
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Grid container>
            <Hidden lgDown>
              <Grid item lg={12} className={styles.elevateTopSpacer}></Grid>
            </Hidden>
            <Grid item md={6}>
              <Typography variant="h1" className={styles["main-title"]}>
                Take control of your heart health with KardiaComplete
                {/* <span className={globalStyles["product-name"]}>
                  KardiaComplete
                </span> */}
              </Typography>
              <p className={styles.elevateCopy}>
                Using a combination of easy to use devices and personal heart
                health guide to take your heart health to the next level. This
                free service is available to all Acme Co. employees who are USA
                residents and living with either hypertension or atrial
                fibrilation.
              </p>
              <SolidButton
                link={`enroll/${params.sponsor}`}
                text="enroll in kardiacomplete"
              />
            </Grid>
            <Grid item md={6}>
              <div style={{ width: "100%" }}>
                <img src={hero} alt="" width="100%" />
              </div>
            </Grid>
            <Hidden lgDown>
              <Grid item lg={12} className={styles.elevateBottomSpacer}></Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <img src={desk} style={{ display: "block", width: "100%" }} alt="" />
        </Grid>
        <Grid item md={6}>
          <Grid container>
            <Hidden lgDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid item xs={10}>
              <Typography variant="h2" className={styles["main-title"]}>
                KardiaComplete
              </Typography>
              <Typography variant="h2" className={styles["sub-title"]}>
                More knowledge, more power,
                <br />
                more support
              </Typography>
              <div className={styles.kardiaCompleteBody}>
                KardiaComplete provides exclusive features in the kardia app
                that will help you better manage your heart health from home.
              </div>
              <div className={styles.kardiaCompleteFeatureList}>
                <Typography variant="h4">Heart Health Kit</Typography>
                <div>What is included in the HealthKit</div>
                <Typography variant="h4">Live 1:1 Coaching</Typography>
                <div>Human approach</div>
              </div>
            </Grid>
            <Hidden lgDown>
              <Grid item xs={1}></Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden lgDown>
          <Grid item lg={12} className={styles.eligibilityTopSpacer}></Grid>
        </Hidden>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>
        <Grid item xs={10}>
          <Typography variant="h2" className={styles["main-title"]}>
            Eligibility
          </Typography>
          <Typography variant="h2" className={styles["sub-title"]}>
            If you have Hypertension, Atrial Fibrillation, or both.
          </Typography>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>
        <Hidden lgDown>
          <Grid item xs={2}></Grid>
        </Hidden>
        <Grid item xs={12} lg={3} md={4}>
          <Typography variant="h3" className={styles.eligibilitySubHead}>
            Hypertension
          </Typography>
          <div>
            Learn more about this feature and why you care about it. There is
            probably a lot we can say about it.
          </div>
        </Grid>
        <Grid item xs={12} lg={3} md={4}>
          <Typography variant="h3" className={styles.eligibilitySubHead}>
            Atrial Fibrillation
          </Typography>
          <div>
            Learn more about this feature and why you care about it. There is
            probably a lot we can say about it.
          </div>
        </Grid>
        <Grid item xs={12} lg={3} md={4}>
          <Typography variant="h3" className={styles.eligibilitySubHead}>
            Disqualifiers
          </Typography>
          <div>
            Learn more about this feature and why you care about it. There is
            probably a lot we can say about it.
          </div>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={2}></Grid>
        </Hidden>
        <Grid item xs={12}>
          <div className={styles.outlinedButton2}>
            <SolidButton
              link={`enroll/${params.sponsor}`}
              text="enroll in kardiacomplete"
              className={styles.outlinedButton2}
            />
          </div>
        </Grid>
        <Hidden lgDown>
          <Grid item lg={12} className={styles.eligibilityBottomSpacer}></Grid>
        </Hidden>
        <Grid item xs={12} className={styles.kardiaCompleteBanner}>
          <Typography variant="h2">KardiaComplete</Typography>
          <Typography variant="h2" style={{ color: "#CFD8E7" }}>
            Here is what the program is all about.
          </Typography>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        {/* Heart health kit section start */}
        <Grid item lg={5} md={6} sm={12}>
          <Typography variant="h2" className={styles.heartHealthHeadline}>
            Heart Health Kit
          </Typography>
          <Typography variant="h2" className={styles.heartHealthSubHeadline}>
            What’s included
          </Typography>
          <ul className={styles.heartHealthList}>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Kardia Mobile 6L
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Omron Evolv
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
          </ul>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        <Grid item lg={5} md={6} sm={12}>
          <div style={{ width: "100%" }}>
            <img src={devices} width="100%" alt="" />
          </div>
          {/* <div style={{ position: "relative", top: "-50px", left: "200px" }}>
              <img src={omronDevice} alt="" />
            </div> */}
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        {/* Heart health kit section finish */}
        {/* Live 1:1 Coaching section start */}
        <Grid item lg={5} md={6} sm={12}>
          <div style={{ width: "100%" }}>
            <img src={illustration} width="100%" alt="" />
          </div>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        <Grid item lg={5} md={6} sm={12} className={gridStyles.bottomSpacing}>
          <Typography variant="h2" className={styles.heartHealthHeadline}>
            Live 1:1 Coaching
          </Typography>
          <Typography variant="h2" className={styles.heartHealthSubHeadline}>
            Bring back the human experience
          </Typography>
          <ul className={styles.heartHealthList}>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Kardia Mobile 6L
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Omron Evolv
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
          </ul>
        </Grid>

        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        {/* Live 1:1 Coaching section end */}
        {/* On Demand Cardio Consult section start */}

        <Grid item lg={5} md={6} sm={12}>
          <Typography variant="h2" className={styles.heartHealthHeadline}>
            On-Demand Cardio Consult
          </Typography>
          <Typography variant="h2" className={styles.heartHealthSubHeadline}>
            Bringing back the human experience
          </Typography>
          <ul className={styles.heartHealthList}>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Kardia Mobile 6L
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Omron Evolv
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
          </ul>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        <Grid item lg={5} md={6} sm={12} className={gridStyles.bottomSpacing}>
          <div style={{ width: "100%" }}>
            <img src={illustration} width="100%" alt="" />
          </div>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        {/* On Demand Cardio Consult section end */}
        {/* Ask kardia section start */}
        <Grid item lg={5} md={6} sm={12} className={gridStyles.bottomSpacing}>
          <div style={{ width: "100%" }}>
            <img src={illustration} width="100%" alt="" />
          </div>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        <Grid item lg={5} md={6} sm={12}>
          <Typography variant="h2" className={styles.heartHealthHeadline}>
            Ask-Kardia
          </Typography>
          <Typography variant="h2" className={styles.heartHealthSubHeadline}>
            Support when you need it
          </Typography>
          <ul className={styles.heartHealthList}>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Kardia Mobile 6L
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Omron Evolv
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
          </ul>
        </Grid>

        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>

        {/* Ask kardia section end */}
        {/* Condition specific programming start */}
        <Grid item xs={10}>
          <Typography variant="h2" className={styles.heartHealthHeadline}>
            Condition-Specific Programming
          </Typography>
          <Typography variant="h2" className={styles.heartHealthSubHeadline}>
            Weekly heart health program
          </Typography>
        </Grid>
        <Hidden lgDown>
          <Grid item xs={1}></Grid>
        </Hidden>
        <Hidden lgDown>
          <Grid item lg={1}></Grid>
        </Hidden>
        <Grid item lg={5} md={6} xs={12}>
          <ul className={styles.heartHealthList}>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Biometric monitoring
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Personal goal setting
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                1:1 Heart Health Coaching
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
          </ul>
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
          <ul className={styles.heartHealthList}>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Customized Educational Content
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                Regular Heart Health Assessments
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
            <li>
              <Typography variant="h4" className={styles.itemTitle}>
                On-Demand Cardio Consult access
              </Typography>
              Information about how we are the #1 doctor recommended personal
              ECG device.
            </li>
          </ul>
          <Hidden lgDown>
            <Grid item lg={1}></Grid>
          </Hidden>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "100px", marginTop: "50px" }}>
          <Grid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              {/* <SolidButton link={`enroll/${params.sponsor}`} text="enroll" fullWidth={true} /> */}
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Grid>
        {/* Condition specific programming section end */}
        <Grid item xs={12} className={styles.conditionBottomSpacer}></Grid>
      </Grid>
    </Shell>
  );
};

export default Hero;
