const FORM_PROGRAM_TITLE = {
  HP: "Please select the statement(s) below that apply to you. To be eligible for KardiaComplete, you should have received an Atrial Fibrillation diagnosis.",
  AC: "Please select the statement(s) below that apply to you. To be eligible for KardiaComplete, you should have received an Atrial Fibrillation diagnosis.",
  BOLD: "Please select the statement(s) below that apply to you. To be eligible for KardiaComplete, you should have received an Atrial Fibrillation diagnosis.",
  ACHQ: "Please select the statement(s) below that apply to you.",
  CVS: "Please select the statement(s) below that apply to you. To be eligible for KardiaComplete, you should have received an Atrial Fibrillation or other arrhythmia diagnosis.",
  NITERRA:
    "Please select the statement(s) below that apply to you. To be eligible for KardiaComplete, you should have received an Atrial Fibrillation diagnosis.",
};

const ERROR = {
  PROGRAM:
    "At least one of Atrial Fibrillation or other Arrhythmia Diagnosis must be selected to proceed",
};
export { FORM_PROGRAM_TITLE, ERROR };
