import React from 'react';
import Shell from '../shell';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from '../../assets/css/colors.module.css';

const termsUseStyles = makeStyles({
  headline: {
    color: colors.blue
  }
});
const Terms = () => {
  const termsStyles = termsUseStyles();
  return (
    <Shell>
      <Grid container>
        <Grid item lg={1}></Grid>
        <Grid item lg={11}>
          <Typography variant="h2" className={termsStyles.headline}>Terms &amp; Conditions</Typography>
          <p>
            Using a combination of easy to use devices and personal heart health guide to take your heart health to the next level. This free service is available to all Acme Co. employees who are USA residents and living with either hypertension or atrial fibrilation.
          </p>
          <p>
            Using a combination of easy to use devices and personal heart health guide to take your heart health to the next level. This free service is available to all Acme Co. employees who are USA residents and living with either hypertension or atrial fibrilation.
          </p>
        </Grid>
      </Grid>
    </Shell>
  );
}

export default Terms;