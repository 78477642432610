import React, { useState } from 'react';
import { Box, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningImage from "../../assets/img/Vector.png";
import styles from './index.module.css'
import CheckBoxSelect from 'components/checkBoxSelect';
import { BorderButton, FilledButton } from 'components/buttons';
import { useSelector } from 'react-redux';
import { verifyingShipping } from 'data';
import useMediaQuery from 'hooks/mediaQuery';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  border: "none !important",
  borderRadius: 2,
};

export const VerifyAddress = (props) => {
  const [enteredAddress, setEnteredAddress] = useState(true);
  const [suggestedAddress, setSuggestedAddress] = useState(false);
  const user = useSelector(state => state.user);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTab = useMediaQuery('(max-width: 1200px)');

  const getLabel = (type, data) => {
    return (
      <div className={styles.checkBoxText}>
        <div className={styles.checkBoxHeader}>{type} </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className={styles.checkBoxSubHeader}>{`${data?.primaryLine}, ${data?.secondaryLine}`}</span>
          <span className={styles.checkBoxSubHeader}>{data?.city}, {data?.state} {data?.zipCode}</span>
        </div>
      </div>
    )
  }

  const handleRadioChange = () => {
    setEnteredAddress(address => !address);
    setSuggestedAddress(address => !address);
  }

  const onSubmit = async () => {
    try {
      let resData = await verifyingShipping(enteredAddress === true ? props.enteredData : props.suggestedData, user?.data);
      if (resData.status === 200) {
        props.handleNavigate("Confirmation");
      } else {
        props.handleErrorModalOpen()
      };

    } catch (err) {
      console.log(err);
    }
    props.handleClose();
  }

  const getSuggestData = () => {
    return {
      shippName:props.suggestedData?.name.split(" ")[0],
      shippingLastName:props.suggestedData?.name.split(" ")[1],
      primaryAddress:props.suggestedData?.primaryLine,
      secondaryAddress:props?.suggestedData?.secondaryLine,
      shippingCity:props?.suggestedData?.city,
      shippingState:props?.suggestedData?.state,
      shippingPostalCode:props?.suggestedData?.zipCode,
    }
  }

const closeModal = () => {
  props.updateShippingnValues(enteredAddress === true ? props.shippingnValues : getSuggestData())
  props.handleClose();
}
  return (
    <Fade in={true}>
      <Box className= {styles.boxStyle} sx={style}>
        <div className={styles.crossStyle}>
          <CloseIcon
            style={{
              background: "#F3F5FA",
              padding: "0.30rem",
              borderRadius: 5,
              cursor: "pointer",
              color: "#174E8C",
              border: "1px solid #174E8C"
            }}
            onClick={props.handleClose}
          />
        </div>
        <div className={styles.imageTextFlex}>
          <img src={WarningImage} style={{ width: 36, height: 32 }} /> <div className={styles.warningText}>Please verify your address </div>
        </div>
        <div className={styles.subTextWarning}>Please choose the version of the address you want to use or click “edit address” to make changes.</div>
        <div className={styles.checkBoxFlex}><CheckBoxSelect onChange={handleRadioChange} checked={enteredAddress} label={getLabel("You Entered:", props.enteredData)} /></div>
        {(props.suggestedData?.primaryLine !== undefined) && <>
          <div className={styles.lineHR}></div>
          <div className={styles.checkBoxFlex}><CheckBoxSelect onChange={handleRadioChange} checked={suggestedAddress} label={getLabel("Suggested Address:", props.suggestedData)} /></div></>}
        <Box className={styles.button_alignment}>
          <FilledButton
            // sponsor={params.sponsor}
            text={`continue with this address`}
            // width={`${isMobile ? "80vw" :  isTab? "37vw" : "18.6vw"}`}
             width = {'100%'}
            blockSize = {"50%"}
            noMargin={true}
            submit={onSubmit}
            fontSize = {"0.75rem"}
            padding = {"10px 0"}
          />
          <BorderButton
            submit={closeModal}
            text={`Edit address`}
            // width={`${isMobile ? "80vw" :  isTab? "37vw" : "18.6vw"}`}
            width = {'100%'}
            blockSize = {"50%"}
            noMargin={true}
            fontSize = {"0.75rem"}
            padding = {"10px 0"}
          />
        </Box>
      </Box>
    </Fade>
  )
}

export default VerifyAddress;