const race = [
  "American Indian or Alaska Native",
  "Asian",
  "Black or African American",
  "Hispanic, Latino, or Spanish",
  "Native Hawaiian or Others",
  "White or Caucasian",
  "Other or not listed",
];

const sex = [
  { name: "Male", value: "male", id: 1 },
  { name: "Female", value: "female", id: 2 },
  { name: "I prefer not to say", value: "prefer_not_to_say", id: 3 },
];

const counts = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Seven: 7,
  Eight: 8,
  Nine: 9,
  Ten: 10,
  Eleven: 11,
  Twelve: 12,
};
export const screenNames = {
  ACCOUNT: 0,
  PROFILE: 1,
  SHIPPING: 2,
  LOGIN: 3,
  CONFIRMATION: 4,
};
const screenIndex = [
  { key: screenNames.ACCOUNT, open: true },
  { key: screenNames.PROFILE, open: false },
  { key: screenNames.SHIPPING, open: false },
  { key: screenNames.LOGIN, open: false },
  { key: screenNames.CONFIRMATION, open: false },
];

export { race, sex, counts, screenIndex };

export const USER_PROGRESS_STEPS = {
  ACCOUNT_CREATION: "AccountCreation",
  SETTING_PROFILE: "SetupProfile",
};

export const EXISTING_USER_STEPS_PROFILE = [
  "Log In",
  "Profile Setup",
  "Shipping Information",
  "Download App",
];
export const EXISTING_USER_STEPS_SHIPPING = [
  "Log In",
  "Shipping Information",
  "Download App",
];

export const stepper_text = {
  LOG_IN: "Log In",
  ENROLMENT: "Enrollment",
  SHIPPING: "Shipping Information",
  CONFIRMATION: "Download App",
};

export const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.alivecor.aliveecg.simba&pli=1";
export const APP_STORE_LINK =
  "https://apps.apple.com/us/app/kardiacomplete/id1611446187";
