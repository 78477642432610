import React, { useState, createRef } from "react";
import { useParams } from "react-router-dom";
import getTextConstants from "constants/copy";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import styles from "./index.module.css";
import { Input, isFormValid } from "components/controls";
import StepperMessage from "components/stepperMessage";
import { FilledButton } from "components/buttons";
import { useSelector } from "react-redux";
import {  
  screenNames,
  sex,
} from "constants/enrollment";
import Shell from "components/shell";
import { profileAPI } from "data";
import { formValid, getFormatedSelectInputValueForEnrolmentForm } from "utilities";
import { ErrorModal } from "components/errorModal";

export const Account = (props) => {
  const [subject, setSubject] = useState(useParams().subject);
  const [loading, updateLoading] = useState(false);
  const formRef = createRef();
  const [profileValues, updateProfileValues] = useState({});
  const [submitable, updateSubmitable] = useState(false);
  const user = useSelector((state) => state.user);
  const dataList = ["feet", "inches", "sex"];
  const [openErrorModal, setOpenErrorModal] = useState({
    open: false,
    code: null,
  });

  const [openSexTooltip, setOpenSexToolTip] = React.useState(false);
  const [openRaceTooltip, setOpenRaceToolTip] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenSexToolTip(false);
    setOpenRaceToolTip(false);
  };

  const handleTooltipOpen = () => {
    setOpenSexToolTip(true);
  };
  const handleTooltipRaceOpen = () => {
    setOpenRaceToolTip(true);
  };

  const handleErrorModalClose = () => {
    setOpenErrorModal({ open: false });
  };
  const handleErrorModalOpen = (data = [], message = "", code = "") =>
    setOpenErrorModal({
      open: true,
      target: data,
      message: message,
      code: code,
    });

  const messages = {
    title: getTextConstants(`enrollment.title.${subject}`),
    subtitle: getTextConstants(`enrollment.subtitle.${subject}`),
    allFieldsRequired: getTextConstants(
      `enrollment.form.allFieldsRequired.${subject}`
    ),
    accountFormTitle: getTextConstants(
      `enrollment.form.accountFormTitle.${subject}`
    ),
    height: getTextConstants(`enrollment.form.height.title`),
    heightSubText: getTextConstants(`enrollment.form.height.subtitle`),
    inches: getTextConstants(`enrollment.form.inches.title`),
    sex: getTextConstants(`enrollment.form.sex.title`),
    sexSubtext: getTextConstants(`enrollment.form.sex.subtitle`),
  };

  const handleRegistrationChange = (e) => {
    const target = e.target;
    let newValue = target.value;
    const name = target.name;
    let profileData = profileValues;
    profileData = { ...profileValues, [name]: newValue };
    updateProfileValues(profileData);
    updateSubmitable(formValid(profileData, dataList));
    // updateSubmitable(isFormValid(formRef.current));
  };

  const getDOB = (dob) => {
    let string = "";
    if (dob) {
      string = dob.split("-");
      string = `${string[1]}-${string[2]}-${string[0]}`;
    }
    return string;
  };

  const fieldConfigs = {
    firstName: {
      type: "noInput",
      label: `First Name`,
      name: "profileFirstName",
      textValue: user?.data?.first_name,
    },
    lastName: {
      type: "noInput",
      label: `Last Name`,
      name: "profileLastName",
      textValue: user?.data?.last_name,
    },
    dateOfBirth: {
      type: "noInput",
      label: `Date of Birth`,
      name: "dateOfBirth",
      textValue: getDOB(user?.data?.patient_dob),
    },
    height: {
      type: "label-only", //form element which will account only to render label and header texts
      label: messages.height,
      subText: messages.heightSubText,
    },
    feet: {
      type: "select",
      label: "",
      value: profileValues.feet,
      name: "feet",
      validation: ["selected"],
      defaultOptionName: profileValues?.feet,
      options:  getFormatedSelectInputValueForEnrolmentForm(10),
      placeholder: "Feet",
      onChange: handleRegistrationChange,
    },
    inches: {
      type: "select",
      label: "",
      value: profileValues?.inches,
      name: "inches",
      onChange: handleRegistrationChange,
      validation: ["selected"],
      defaultOptionName: profileValues?.inches,
      options:  getFormatedSelectInputValueForEnrolmentForm(12),
      placeholder: "Inches",
      asterik: true,
    },
    sex: {
      type: "select",
      label: messages.sex,
      value: profileValues?.sex,
      name: "sex",
      onChange: handleRegistrationChange,
      validation: ["selected"],
      defaultOptionName: profileValues?.sex,
      options: sex,
      placeholder: "Sex",
      subText: messages.sexSubtext,
    },
  };

  const onSubmit = async () => {
    const body = {
      dob: user?.data?.patient_dob,
      sex: profileValues.sex,
      height: parseInt(
        profileValues?.feet * 30.48 + profileValues?.inches * 2.54
      ),
      conditions: null,
      first_name: user?.data?.first_name,
      last_name: user?.data?.last_name,
      address: null,
      smoker: false,
    };
    try {
      updateLoading(true);
      let resData = await profileAPI(body, user?.data);
      if (resData.status === 200) {
        let res = await resData.json();
        props.handleNavigate(screenNames.SHIPPING);
      } else handleErrorModalOpen();
    } catch (err) {
      console.log(err);
    }
    updateLoading(false);
  };

  return (
    <Shell>
      <Grid container className={styles.container}>
        <Grid item xs={0} md={0} lg={2}></Grid>
        <Grid item xs={12} md={12} lg={8} className={styles.titles}>
          <StepperMessage
            title={messages.title}
            subtitle={messages.subtitle}
            activeStep={1}
          />
        </Grid>
        <Grid item xs={0} md={0} lg={2}></Grid>
      </Grid>
      <Grid
        sm={6}
        md={6}
        lg={6}
        container
        order={{ xs: 3, md: 2 }}
        className={styles.backdropContainer}
      >
        <Backdrop open={loading} className={styles.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <form className={styles["registration-form"]} ref={formRef}>
        <Typography variant="h3" className={styles["registration-headline"]}>
          {messages.accountFormTitle}{" "}
          <span className={styles["all-fields-required"]}>
            {messages.allFieldsRequired}
          </span>
        </Typography>
        <Grid container spacing={1} className={styles["form-fields"]}>
          <Grid item xs={6} className={styles["form-row"]}>
            <Input {...fieldConfigs.firstName} />
          </Grid>
          <Grid item xs={6} className={styles["form-row"]}>
            <Input {...fieldConfigs.lastName} />
          </Grid>
          <Grid item xs={12} className={styles["form-row"]}>
            <Input {...fieldConfigs.dateOfBirth} />
          </Grid>
          <Grid item xs={12} className={styles["label-only"]}>
            <Input {...fieldConfigs.height} />
          </Grid>
          <Grid item xs={6} className={styles["no-label-input"]}>
            <Input {...fieldConfigs.feet} />
          </Grid>
          <Grid item xs={6} className={styles["no-label-input"]}>
            <Input {...fieldConfigs.inches} />
          </Grid>
          <Grid item xs={12} className={styles["form-row"]}>
            <Input {...fieldConfigs.sex} />
          </Grid>
        </Grid>
        <div className={styles.buttonWrapper}>
          {" "}
          <FilledButton
            sponsor={useParams().sponsor}
            text={`Continue`}
            disabled={!submitable}
            width={"100%"}
            noMargin={true}
            submit={onSubmit}
          />
        </div>
      </form>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openErrorModal.open}
          onClose={handleErrorModalClose}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <ErrorModal
            handleClose={handleErrorModalClose}
            open={openErrorModal.open}
            code={openErrorModal.code}
            text={"Something went wrong."}
            subText={
              <>
                <span>Please contact </span>
                <a href="mailto:support@alivecor.com">support@alivecor.com.</a>
              </>
            }
          />
        </Modal>
      </div>
    </Shell>
  );
};

export default Account;
