export const SUBJECT_EMPLOYEE = "employee";
export const SUBJECT_DEPENDENT = "dependent";
export const SUBJECT_PROVIDER = "provider";
export const SPONSOR_HP = "HP";
export const SPONSOR_CVS = "CVS";
export const BOLD = "BOLD";
export const SPONSOR_AC = "AC";
export const SPONSOR_ACHQ = "ACHQ";
export const SPONSOR_NITERRA = "NITERRA";
export const NO_USER_FOUND = "Cannot find User";
export const USER_ERRORED_CODE_GENERAL_1 = "E#HQVS7";
export const USER_EXIST_CODE_GENERAL_2 = "E#HQVS8";
export const USER_EXIST_CODE_CVS_1 = "E#HSVCV";
export const USER_NOT_EXIST_CODE = "E#HS8CV";
export const CHAT_USER_NOT_CREATED = "E#HH5SV";
export const USER_DEPENDENT_ERROR_1 = "E#HQISK";
export const USER_DEPENDENT_ERROR_2 = "E#R1IHO";
export const USER_DEPENDENT_ERROR_3 = "E#Q41N4";
export const USER_DEPENDENT_ERROR_4 = "E#HSVCO"; 