const LOGIN_HEADER = "Are you new to KardiaComplete?";
const CREATE_ACCOUNT_LINK_TEXT = "Create an Account";
const FORGOT_PASSWORD_LINK_TEXT = "Forgot your password?";

const INVAILD_USERNAME_ERROR =
  "You have entered an invalid username or password";
const DEFAULT_ERROR_TEXT = "Something went wrong";
const USER_NOT_FOUND_ERROR = "User Not Found.";
const ACCOUNT_EXIST =
  "This account is already in use on our Kardia app, not KardiaComplete.";
const ERROR_SUBTEXT =
  "Please use another email address or reach out to our Customer Care Team to continue";
export {
  LOGIN_HEADER,
  CREATE_ACCOUNT_LINK_TEXT,
  FORGOT_PASSWORD_LINK_TEXT,
  INVAILD_USERNAME_ERROR,
  DEFAULT_ERROR_TEXT,
  USER_NOT_FOUND_ERROR,
  ACCOUNT_EXIST,
  ERROR_SUBTEXT,
};
