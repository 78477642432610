const states = [
  { name: 'Alabama',value:'AL', id: 'AL' },
  { name: 'Alaska', value:'AK', id: 'AK' },
  { name: 'Arizona',value:'AZ', id: 'AZ' },
  { name: 'Arkansas',value:'AR', id: 'AR' },
  { name: 'California',value:'CA', id: 'CA' },
  { name: 'Colorado',value:'CO', id: 'CO' },
  { name: 'Connecticut',value:'CT', id: 'CT' },
  { name: 'Delaware',value:'DE', id: 'DE' },
  { name: 'Florida', value:'FL',id: 'FL' },
  { name: 'Georgia',value:'GA', id: 'GA' },
  { name: 'Hawaii', value:'HI',id: 'HI' },
  { name: 'Idaho',value:'ID', id: 'ID' },
  { name: 'Illinois',value:'IL', id: 'IL' },
  { name: 'Indiana', value:'IN',id: 'IN' },
  { name: 'Iowa',value:'IA', id: 'IA' },
  { name: 'Kansas',value:'KS', id: 'KS' },
  { name: 'Kentucky',value:'KY', id: 'KY' },
  { name: 'Louisiana',value:'LA', id: 'LA' },
  { name: 'Maine', value:'ME',id: 'ME' },
  { name: 'Maryland',value:'MD', id: 'MD' },
  { name: 'Massachusetts', value:'MA',id: 'MA' },
  { name: 'Michigan',value:'MI', id: 'MI' },
  { name: 'Minnesota', value:'MN',id: 'MN' },
  { name: 'Mississippi',value:'MS', id: 'MS' },
  { name: 'Missouri',value:'MO', id: 'MO' },
  { name: 'Montana',value:'MT', id: 'MT' },
  { name: 'Nebraska',value:'NE', id: 'NE' },
  { name: 'Nevada',value:'NV', id: 'NV' },
  { name: 'New Hampshire',value:'NH', id: 'NH' },
  { name: 'New Jersey',value:'NJ', id: 'NJ' },
  { name: 'New Mexico',value:'NM', id: 'NM' },
  { name: 'New York', value:'NY',id: 'NY' },
  { name: 'North Carolina',value:'NC', id: 'NC' },
  { name: 'North Dakota',value:'ND', id: 'ND' },
  { name: 'Ohio',value:'OH', id: 'OH' },
  { name: 'Oklahoma',value:'OK', id: 'OK' },
  { name: 'Oregon',value:'OR', id: 'OR' },
  { name: 'Pennsylvania',value:'PA', id: 'PA' },
  { name: 'Rhode Island', value:'RI',id: 'RI' },
  { name: 'South Carolina',value:'SC', id: 'SC' },
  { name: 'South Dakota',value:'SD', id: 'SD' },
  { name: 'Tennessee',value:'TN', id: 'TN' },
  { name: 'Texas',value:'TX', id: 'TX' },
  { name: 'Utah',value:'UT', id: 'UT' },
  { name: 'Vermont', value:'VT',id: 'VT' },
  { name: 'Virginia',value:'VA', id: 'VA' },
  { name: 'Washington',value:'WA', id: 'WA' },
  { name: 'West Virginia',value:'WV', id: 'WV' },
  { name: 'Wisconsin', value:'WI',id: 'WI' },
  { name: 'Wyoming',value:'WY', id: 'WY' }
]

export default states;

