import { useEffect } from "react";
import Shell from "components/shell";
import { Grid, Typography } from "@mui/material";
import Success from "../../assets/img/Success.png";
import { OutlinedButton } from "components/buttons";
import { useParams } from "react-router-dom";
import {
  SUBJECT_EMPLOYEE,
  SUBJECT_DEPENDENT,
  SUBJECT_PROVIDER,
} from "../../constants/app";
import QrCode from "../../assets/img/QrCode.png";
import appStore from "../../assets/img/appstore.png";
import playStore from "../../assets/img/playstore.png";
import classes from "./index.module.css";
import StepperMessage from "components/stepperMessage";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "constants/enrollment";

const Confirmation = () => {
  const fetchUserName = () => {
    const name = JSON.parse(localStorage.getItem("_data")).firstName || "User";
    return name;
  };
  // const containerStyles = containerUseStyles();
  const { sponsor, code, subject } = useParams();
  const addDependentLink = `/enroll/${sponsor}/provider`;
  const enrollMyselfLink = `/enroll/${sponsor}/${SUBJECT_EMPLOYEE}`;

  useEffect(() => window.scrollTo(0, 0));

  /**
   * feature ticket --> ASP-281
   * return logic to navigate to play store or app store based on user's device.
   */
  const appsStoreNavigation = () => {
    if (navigator.userAgent.match(/Android/i)) {
      window.location.href = PLAY_STORE_LINK;
    } else if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      window.location.href = APP_STORE_LINK;
    }
  };
  // ========= end ========== //

  return (
    <Shell confirmation={true}>
      <Grid container className={classes.container}>
        <Grid item xs={0} md={0} lg={12}></Grid>
        <Grid item xs={12} md={12} lg={8} className={classes.titles}>
          {/* activeSteps here refers to array index of Stepper 
            this will point to index of current step 
          */}
          <StepperMessage activeStep={2} />
        </Grid>
        <Grid item xs={0} md={0} lg={12}></Grid>
      </Grid>
      {((subject === SUBJECT_EMPLOYEE || subject === SUBJECT_DEPENDENT) && (
        <Grid container className={classes.confirmation}>
          <Grid item md={12} className={classes["completion-message"]}>
            <Typography variant="h4" className={classes.enrollment_header}>
              Download the KardiaComplete App to get started.
            </Typography>

            {/* below text differs if user uses web browser or mobile browser
             * feature ticket --> ASP-281
             */}
            <Typography
              variant="subtitle1"
              className={classes["success-message"]}
            >
              {navigator.userAgent.match(/Android/i) ||
              navigator.userAgent.match(/iPhone/i) ||
              navigator.userAgent.match(/iPad/i)
                ? `Select Google Play or the App Store to download the KardiaComplete
              App.`
                : `Select Google Play or the App Store to download the KardiaComplete
              App or scan the QR code with your phone.`}
            </Typography>

            <div className={classes.store}>
              <img
                alt="apple store"
                src={appStore}
                onClick={appsStoreNavigation}
              />
              <img
                alt="play store"
                src={playStore}
                onClick={appsStoreNavigation}
              />
            </div>
            <img
              alt="qrcode to download kcc app"
              src={QrCode}
              className={classes.qrCode}
            />
            <Typography
              variant="subtitle1"
              className={classes["success-message"]}
            >
              You will receive a confirmation email from support@alivecor.com
              confirming when your Heart Health Kit has shipped.
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes["success-message"]}
            >
              If you have questions, please reach out to our Customer Care team.
            </Typography>
          </Grid>
        </Grid>
      )) ||
        (subject === SUBJECT_PROVIDER && (
          <Grid container className={classes.confirmation}>
            <Grid item md={6} className={classes["completion-message"]}>
              <img src={Success} />
              <Typography variant="h1" className={classes.enrollment_header}>
                Dependent Added
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes["success-message"]}
              >
                Your dependent will receive an invitation email from
                support@alivecor.com to begin their enrollment.
              </Typography>
              <div className={classes["add-dependent-container"]}>
                <OutlinedButton
                  link={enrollMyselfLink}
                  text="enroll myself"
                  size={"24vw"}
                  variant="contained"
                />
              </div>
            </Grid>
          </Grid>
        ))}
    </Shell>
  );
};

export default Confirmation;
