import React from 'react';
import styles from './NotFoundPage.module.css';
// import DashboardIcon from 'assets/images/poll.svg';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
} from "@mui/material";
import Shell from 'components/shell';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const handleRouteToDashboard = () => {
    // history.push(ROUTES.ROOT);
  };
  return (
    // <Shell>
    <>
        {/* {redirect ? <Redirect to="/confirmation" /> : ""} */}
    <Grid container>
    <div data-page="not-found-page" className={styles.NotFoundPage}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection:"column"
        }}
      >
        <h1>404</h1>
        <h2>Sorry.</h2>
        <p>Page Not Found.</p>
     
      </div>
    </div>
    </Grid>
    </>
    // </Shell>
  );
};

export default NotFoundPage;
