import NotFoundPage from "components/NotFoundPage";
import Account from "components/account";
import Login from "components/login";
import Register from "components/register";
import Shipping from "components/shipping";
import { screenIndex, screenNames } from "constants/enrollment";
import { getSponsorRules } from "data";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getPage } from "utilities";

export const Enroll = () => {
  let [page, setPage] = useState(screenIndex);
  let [rules, setRules] = useState();
  let [count, setCount] = useState(0);
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);
  let subject = useParams().subject;
  let sponsor = useParams().sponsor.toUpperCase();
  const location = useLocation();

  const handleNavigate = (id = screenNames.LOGIN) => {
    if (id === "Confirmation") {
      navigate(`/confirmation/${sponsor}/${subject}`);
    } else {
      let array = getPage(id, page);
      setPage(array);
    }
    setCount((count) => count + 1);
  };
  useEffect(() => {
    if (location.search === "?mode=login") {
      handleNavigate(screenNames.LOGIN);
    }
    async function getRules() {
      let data = await getSponsorRules(sponsor);
      let resp = await data.json();
      setRules(resp);
    }
    getRules();
  }, []);
  if (rules?.errors?.length > 0) {
    return (
      <div>
        <NotFoundPage />
      </div>
    );
  } else {
    return (
      <div>
        {page[0]?.open === true && (
          <Register
            setPage={setPage}
            rules={rules}
            page={page}
            handleNavigate={handleNavigate}
          />
        )}
        {page[1]?.open === true && (
          <Account
            setPage={setPage}
            page={page}
            handleNavigate={handleNavigate}
          />
        )}
        {page[2]?.open === true && (
          <Shipping
            setPage={setPage}
            page={page}
            handleNavigate={handleNavigate}
          />
        )}
        {page[3]?.open === true && (
          <Login
            setPage={setPage}
            page={page}
            handleNavigate={handleNavigate}
          />
        )}
      </div>
    );
  }
};

export default Enroll;
