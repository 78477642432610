import { Link } from "react-router-dom";
import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "../../assets/img/AliveCor_logo.png";
import { Twitter, Instagram, LinkedIn, Facebook } from "@mui/icons-material";
import colors from "../../assets/css/colors.module.css";
import globalStyles from "../../assets/css/index.module.css";
import AliveCorLogo from '../../assets/img/KardiaComplete.png'

const containerUseStyles = makeStyles((theme) => ({
  expand: {
    minHeight: "100vh",
  },
  container: {
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  topAlign: {
    alignContent: "start",
  },
  logo: {
    flexShrink: 0,
    height: "96px",
    minHeight: "96px",
  },
  footerLogo: {
    paddingTop: "12px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: 0,
    },
  },
  children: {
    flexGrow: 1,
    flexShrink: 0,
    backgroundColor: "#edf3fa",
  },
  childrenContainer: {
    padding: "50px 10px",
  },
  careTitle: {
    marginTop: "40px",
    [theme.breakpoints.down("lg")]: {
      display: "block",
      textAlign: "center",
    },
  },
  "support-link": {
    [theme.breakpoints.down("lg")]: {
      display: "block",
      marginRight: 0,
      textAlign: "center",
    },
  },
  footer: {
    flexShrink: 0,
    backgroundColor: "white",
    borderTop: `4px solid ${colors.brand}`,
  },
  "social-web-container": {
    position: "relative",
  },
  "social-web": {
    marginTop: "30px",
    [theme.breakpoints.down("lg")]: {
      marginTop: 0,
    },
  },
  domain: {
    fontWeight: 600,
    fontSize: "18px",
    textDecoration: "none",
  },
  domainContainer: {
    textAlign: "right",
    lineHeight: "43px",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
  icons: {
    color: colors.brand,
    textAlign: "right",
    letterSpacing: "5px",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
  icon: {
    fontSize: "20px",
    marginLeft: "21px",
  },
  legalLinks: {
    textAlign: "center",
  },
  footerText:{
   fontSize:"15px",
   color:"#182C3D",
   fontWeight:400,
   fontFamily:"DM Sans",
  },
  legalLink: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "21px",
    display: "inline-block",
    "& a": {
      color: "black",
    },
    marginLeft: "35px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  footerStyle:{
     margin:"25px auto",
     '@media(max-width: 900px)' : {
     marginLeft:"auto 10rem"
     }
    // paddingLeft:"5rem"
  },
  copyright: {
    fontSize: "12px",
    color: colors.smoke,
    textAlign: "center",
  },
  contactStyle:{
    fontSize:"16px",
    color:"#182C3D",
    fontFamily:"DM Sans",
    fontWeight:400
  },
  phoneEmailInfo:{
    marginTop:"5px"
  },
  termsHeader:{
    fontSize:"17px",
    fontWeight:"bold",
    textDecoration:"underline",
    margin:"1.5rem auto",
    cursor:"pointer",
    fontFamily:"Poppins",
    textUnderlineOffset: "2px"
  },
  AliveCorLogo:{
    height:"10vh",
    width:"100%"
  },
  socialPlatform:{
    margin:"3rem auto",
  },
  rightSideFooter:{
    display:"grid",flexDirection:"row",justifyContent:"center",
    '@media(max-width: 900px)' : {
     justifyContent:"space-between",
     marginTop:"2rem"
    }
  },
  logoImage:{
    width:"100px",
    height:"80px",
  }
}));

const Shell = (props) => {
  const containerStyles = containerUseStyles();
  const supportLinkClasses = `${containerStyles["support-link"]} ${globalStyles["support-link"]}`;

  const footerContent = () => {
    return(
      <Grid container className={containerStyles.footerStyle}>
      {/* <hr className={containerStyles.hrStyle}></hr> */}
      <Grid xs={12} lg={12}>
      <Typography variant="body2" className={containerStyles.footerText}>
      &copy;2023 AliveCor, Inc. All Rights Reserved. US Patent Nos 8,301,232, 8,509,882 and Patents Pending. 
        </Typography>
        </Grid>
        <Grid xs={12} lg={12}>
        <Typography variant="body2" className={containerStyles.footerText}>
          AliveCor and Kardia are trademarks of AliveCor, Inc. in the United States and other countries.
        </Typography>
        </Grid>
  
    </Grid>
    )
  }

  return (
    <Grid
      container
      className={`${containerStyles.expand} ${containerStyles.container}`}
    >
      <Grid item lg={12} className={containerStyles.logo}>
        <Container maxWidth="lg" style = {{display:"flex",justifyContent:"center",marginTop:"7px"}}>
        <img src = {AliveCorLogo} alt = "AliveCorLogo" className={containerStyles.logoImage} />
        </Container>
      </Grid>
      <Grid item lg={12} className={containerStyles.children}>
        <Container
          maxWidth="lg"
          classes={{ root: containerStyles.childrenContainer }}
        >
          {props.children}
        </Container>
      </Grid>
      <Grid item xs={12} lg={12} className={containerStyles.footer}>
         <Container maxWidth="xl">
         {!props.confirmation ? 
        footerContent(): <Grid container className={containerStyles.footerStyle}>
             <Grid xs={1} md = {0.5} lg={0.5}></Grid>
          <Grid xs={11} md = {8.5} lg={8.5}>
          <Typography variant="h4" style = {{fontSize:22}}>
                Customer Care
              </Typography>
              <div className={containerStyles.phoneEmailInfo}>
              <Typography variant="body2" className={containerStyles.contactStyle}>
             phone: (855) 578-1085
            </Typography>
            <Typography variant="body2" className={containerStyles.contactStyle}>
             email: <span style = {{fontSize:"15px",color:"#174E8C"}}> KCSupport@alivecor.com</span>
            </Typography>
            <Typography variant="h6" className={containerStyles.termsHeader}>
          <a href = "https://www.kardia.com/tos/kardiacomplete" style = {{color:"black",fontSize:18}}> Terms & Conditions</a>
            </Typography>
            <Typography variant="h6" className={containerStyles.termsHeader}>
            <a href = "https://www.kardia.com/privacy" style = {{color:"black",fontSize:18}}>Privary Policy</a>
            </Typography>
            {footerContent()}
            </div>
            </Grid>
            <Grid xs={1} md = {0.5}></Grid>
            <Grid xs={11} md = {2.5} lg={2.5}  className = {containerStyles.rightSideFooter}>
              <img src = {Logo} alt = "AliveCorLogo" className={containerStyles.AliveCorLogo} />
              <div className={containerStyles.socialPlatform}>
              <Typography variant="h3" style = {{color:"#174E8C",fontFamily:"DM Sans"}}>
             www.kardia.com
            </Typography>
            <div style={{textAlign:"center",marginTop:"0.7rem"}}>
            <Twitter style = {{color:"#174E8C",margin:" auto 0.2rem "}} />
            <LinkedIn style = {{color:"#174E8C",margin:" auto 0.2rem "}} />
            <Instagram style = {{color:"#174E8C",margin:" auto 0.2rem "}} />
            <Facebook style = {{color:"#174E8C",margin:" auto 0.2rem "}} />
            </div>
            </div>
              </Grid>
              <Grid xs={1} md = {0.5} lg={0.5}></Grid>
            </Grid>}
         </Container>
      </Grid>
    </Grid>
  );
};

export default Shell;
