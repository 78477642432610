import "./App.css";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Theme from "components/theme";
import Enrollment from "components/enrollment";
import Confirmation from "components/confirmation";
import Terms from "components/terms";
import Privacy from "components/privacy";
import Hero from "components/hero";
import { Provider } from 'react-redux';
import { store } from './store'
import Register from "components/register";
import Enroll from "components/enroll";

function App() {
  return (
    <div>
      <Provider store={store}>
        <Theme>
          <CssBaseline>
            <BrowserRouter>
              <Routes>
                <Route path="/enroll/:sponsor" element={<Enrollment />} />
                <Route path="/enroll/:sponsor/:subject" element={<Enroll />} />
                <Route
                  path="/enroll/:sponsor/:subject/:code"
                  element={<Enroll />}
                />
                <Route
                  exact
                  path="/confirmation/:sponsor/:subject"
                  element={<Confirmation />}
                />
                <Route exact path="/terms" element={<Terms />} />
                <Route exact path="/privacy" element={<Privacy />} />
                <Route path="/:sponsor" element={<Hero />} />
                <Route path="/" element={<Hero />} />
                <Route path="*" element={<Hero />} />
              </Routes>
            </BrowserRouter>
          </CssBaseline>
        </Theme>
      </Provider>
    </div>
  );
}

export default App;
