import React from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import styles from './index.module.css';

function Checkbox({ label, icon, checkedIcon, checked, onChange }) {
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox checked={checked} icon={icon} checkedIcon={checkedIcon} onChange={onChange} />
      }
    />
  );
}


export const CheckBoxSelect = (props) => {
  return (
    <div>
      <Checkbox
        label={props.label}
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<RadioButtonCheckedIcon />}
        checked={props.checked}
        onChange={props.onChange}
      />
    </div>
  )
}

export default CheckBoxSelect;